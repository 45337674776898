
/* HealthPackageList.css */
.packages-list {
  padding: 20px;
}

.packages-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: 20px;
}

.package-card {
  position: relative;
  border: 1px solid #e0e0e0;
  border-radius: 10px;
  overflow: hidden;
  background-color: #fff;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column; /* Flexbox to align elements vertically */
  justify-content: space-between; /* Ensures proper spacing between items */
  height: 100%; /* Ensures consistent height for all cards */
}

.package-link {
  text-decoration: none;
  color: inherit;
}

.package-image-container {
  position: relative;
  width: 100%;
  height: 200px;
  overflow: hidden;
}

.package-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: transform 0.3s ease;
}

.package-card:hover .package-image {
  transform: scale(1.05);
}

.hover-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  color: white;
  display: flex;
  flex-direction: column;
  justify-content: flex-start; /* Align content at the top */
  text-align: center;
  opacity: 0;
  transition: opacity 0.3s ease;
  padding: 20px;
}

.package-image-container:hover .hover-overlay {
  opacity: 1;
}

.hover-overlay h2 {
  margin: 0;
  padding: 5px 0;
  margin-top: -25px;
  font-size: 12px;
  font-weight: 900;
}

.hover-overlay ul {
  display: grid;
  grid-template-columns: repeat(2, 1fr); /* 2 columns */
  gap: 2px; /* Reduced spacing between columns */
  list-style: none;
  padding: 0;
  margin-top: 15px;
  font-size: 14px;
}

.hover-overlay li {
  text-align: left; /* Align text to the left */
  font-size: 11px; /* Set font size for the details */
  font-weight: 700;
  margin-bottom: 5px; /* Reduce margin between list items */
}



.package-details {
  padding: 15px;
  flex-grow: 1; /* Allows the package details to take up remaining space */
}

.package-name {
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 10px;
}

.package-desc {
  font-size: 14px;
  color: #555;
}

.extra-details {
  padding: 10px 15px;
  background-color: #f9f9f9;
}

.extra-details ul {
  list-style: none;
  padding: 0;
}

.extra-details li {
  font-size: 14px;
  margin-bottom: 5px;
  color: #555;
}

.extra-details i {
  color: #0F3460;
  margin-right: 5px;
}

.price-cart-box {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 15px;
  background-color: #f9f9f9;
}

.package-price {
  font-size: 16px;
  font-weight: bold;
}

.book-now-button {
  background-color: #0F3460;
  color: #fff;
  border: none;
  padding: 10px;
  border-radius: 5px;
  cursor: pointer;
  width: 70%; /* Set a smaller fixed width */
  text-align: center;
  margin: 20px auto 0; /* Center the button horizontally */
}

.book-now-button:hover {
  background-color: #072a48;
}


.extra-details-with-logo {
  display: flex;
  align-items: flex-start;
  padding: 10px 0;
}

.logo-container {
  margin-right: 15px;
}

.package-logo {
  width: 100px;  /* Adjust the size of the logo */
  height: auto;
}

.extra-details {
  flex-grow: 1;
}

.extra-details ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.extra-details li {
  margin-bottom: 5px;
  font-size: 14px;
}

.price-box {
  display: flex;
  align-items: center;
}

.mrp {
  text-decoration: line-through;
  color: #a1a1a1;
  margin-right: 10px;
  font-size: 16px;
}

.discounted-price {
  color: #ff5722; /* Color for the discounted price */
  font-weight: bold;
  font-size: 18px;
}

