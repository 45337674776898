/* Highlight.css */
.highlight {
  position: absolute;
  top: 10px;
  right: 10px;
  background-color: #0F3460;
  color: #fff;
  padding: 5px 10px;
  border-radius: 5px;
  text-align: center;
  z-index: 1;  /* Ensure it stays on top */
}

.highlight-number {
  font-size: 18px;
  font-weight: bold;
}

.highlight-text {
  font-size: 14px;
}
