.view-orders-container {
  overflow-x: auto; /* Enables horizontal scrolling */
}

.styled-link {
    color: #333;
}

.styled-link {
    display: inline-block;
    padding: 5px 10px;
    margin: 5px;
    border-radius: 5px;
    transition: background-color 0.3s ease;
  }
  
  .styled-link:hover {
    background-color: #f5f5f5; /* Slight gray background on hover */
    color: #666;
  }

  .custom-button {
    background-color: #98adc4; /* Change to your preferred color */
    color: #281616;
    border: none;
    padding: 10px 20px;
    border-radius: 5px;
    font-size: 1rem;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.custom-button:disabled {
    background-color: #aaa;
    cursor: not-allowed;
}

.custom-button:hover:not(:disabled) {
    background-color: #627a94; /* Darkened color on hover */
}


